<template>
    <div>
        <div class="error-page">
            <!-- <div class="error-code">4<span>0</span>4</div> -->
            <div class="error-desc" v-if='isValidating'>信息验证中……</div>
            <div class="error-desc" v-else="">{{msg}}</div>
            <!-- <div class="error-handle">
            <router-link to="/">
                <el-button type="primary" size="large">进入系统</el-button>
            </router-link>
            <el-button class="error-btn" type="primary" size="large" @click="goBack">关闭</el-button>
        </div> -->
        </div>
        <div v-show="!isValidating" class="error_end">——高擎论文评审中心</div>
    </div>
</template>

<script>
    import {
        setToken
    } from '@/tools/auth'
    import {computedAsyncRoutes} from "@/store/modules/user";
    import {asyncRoutes} from "@/router";
    import {cloneDeep} from 'lodash'
    export default {
        name: 'explanation',
        data() {
            return {
                msg: '',
                isValidating: true
            }
        },
        created() {
            let token = this.$route.query['token'];
            this.tokenLogin(token);
        },
        methods: {
            //链接登录
            tokenLogin(token) {
                const loading = this.$loading({
                    lock: true,
                    text: '跳转中',
                    spinner: 'el-icon-loading',
                    background: 'hsla(0,0%,100%,.9)'
                });
                setTimeout(() => {
                    let param = new URLSearchParams();
                    param.append("tokenID", token);
                    this.$api.user.getToken(param)
                        .then(res => {
                            let routes_name_pre = res.data.data.authorities.filter((item)=>{return item['role'].indexOf('btn.') == -1}).map(item=>item['role']);
                            let routes_name = routes_name_pre.filter((item)=>{return item.indexOf('ROLE_') == -1});
                            let buttons_name = res.data.data.authorities.filter((item)=>{return item['role'].indexOf('btn.') != -1}).map(item=>item['role']);
                            if (res.data.code == 200) {

                                this.isValidating = false;
                                // this.$store.dispatch('professor/changeAccount', res.data.data.username);
                                let inner = res.data.data;
                                if (inner.code == 200 || inner.code == 2001 || inner.code == 2002 || inner
                                    .code ==
                                    2006) {
                                    // 获取用户信息
                                    this.$api.user.getInstitution()
                                        .then(res => {

                                          this.institutionName = res.data.data.institutionName
                                          this.$store.dispatch('user/changeInfo', {
                                            // 用户信息赋值
                                            name: '',
                                            institution:this.institutionName,
                                            routes: routes_name,
                                            roles:"BN",
                                            buttons: buttons_name,
                                          })
                                          this.$store.dispatch('user/changeResultAsyncRoutes',
                                              cloneDeep(computedAsyncRoutes(asyncRoutes,this.$store.state.user.info.routes))
                                          )
                                          this.$router.addRoutes(this.$store.state.user.resultAsyncRoutes)

                                          setToken(1);
                                          this.$router.push({
                                            name: 'batchManage'
                                          });
                                          loading.close();
                                        })



                                } else {
                                    this.msg = inner.message;
                                    loading.close();
                                }
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护");
                        })
                }, 1000);
            },
            goBack() {

            }
        }
    }
</script>


<style scoped>
    .error-page {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        /* background: #f3f3f3; */
        box-sizing: border-box;
    }

    .error-code {
        line-height: 1;
        font-size: 250px;
        font-weight: bolder;
        color: #2d8cf0;
    }

    .error-code span {
        color: #00a854;
    }

    .error-desc {
        padding-top: 10px;
        font-size: 30px;
        color: #777;
        font-family: "楷体";
    }

    .error_end {
        padding-top: 10px;
        padding-right: 50px;
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .error-handle {
        margin-top: 30px;
        padding-bottom: 200px;
    }

    .error-btn {
        margin-left: 100px;
    }
</style>